<template>
  <div>
    <v-row justify="center" v-show="dialog">
      <v-dialog v-model="dialog" scrollable max-width="25rem">
        <v-card class="rounded-lg none-overflow">
          <v-card-title style="font-weight: normal; background-color: #ffd7af"
            >设置每日学习计划
          </v-card-title>
          <!--          <v-divider></v-divider>-->
          <v-card-text>
            <v-row class="pt-3">
              <v-col cols="12">
                <div style="width: 18rem; margin: 0 auto">
                  <v-row class="mt-5 flex-end" no-gutters>
                    <v-col class="text-left flex-end" cols="4">
                      <span
                        class="text-h4 font-weight-light"
                        v-text="tempGoles.timeGole"
                        style="margin-bottom: -0.2rem"
                      ></span>
                      <span class="subheading font-weight-light ml-1"
                        >分钟</span
                      >
                    </v-col>
                    <v-col class="text-left flex-end" cols="4">
                      <span
                        class="text-h6 font-weight-light"
                        v-text="tempGoles.classGole"
                        style="margin-bottom: -0.1rem"
                      ></span>
                      <span class="subheading font-weight-light ml-1"
                        >节课程</span
                      >
                    </v-col>
                    <v-col class="text-left flex-end" cols="4">
                      <span
                        class="text-h8 font-weight-light"
                        v-text="
                          tempGoles.newWordGole + tempGoles.reviewWordGole
                        "
                      ></span>
                      <span class="subheading font-weight-light ml-1"
                        >个单词</span
                      >
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-6"
                    justify="space-between"
                    no-gutters
                    style="align-items: center"
                  >
                    <span style="width: 5rem">学习时长</span>
                    <v-slider
                      v-model="tempGoles.timeGole"
                      track-color="grey"
                      always-dirty
                      dense
                      hide-details
                      :min="20"
                      :max="120"
                      :step="10"
                      color="#ffaf64"
                    >
                      <template v-slot:prepend>
                        <v-icon @click="countTime"> mdi-minus </v-icon>
                      </template>

                      <template v-slot:append>
                        <v-icon @click="plusTime"> mdi-plus </v-icon>
                      </template>
                    </v-slider></v-row
                  >
                  <v-row
                    class="mt-4"
                    justify="space-between"
                    no-gutters
                    style="align-items: center"
                  >
                    <span style="width: 5rem">课程数量</span>
                    <v-slider
                      v-model="tempGoles.classGole"
                      track-color="grey"
                      always-dirty
                      dense
                      hide-details
                      :min="1"
                      :max="10"
                      :step="1"
                      color="#ffaf64"
                    >
                      <template v-slot:prepend>
                        <v-icon @click="countClass"> mdi-minus </v-icon>
                      </template>

                      <template v-slot:append>
                        <v-icon @click="plusClass"> mdi-plus </v-icon>
                      </template>
                    </v-slider></v-row
                  >
                </div>
              </v-col>
              <v-col cols="12">
                <div style="width: 18rem; margin: 0 auto">
                  <v-row class="mt-5 flex-end" no-gutters>
                    <v-col class="text-left flex-end" cols="4">
                      <span
                        class="text-h6 font-weight-light"
                        v-text="tempGoles.newWordGole"
                        style="margin-bottom: -0.1rem"
                      ></span>
                      <span class="subheading font-weight-light ml-1"
                        >新词</span
                      >
                    </v-col>
                    <v-col class="text-left flex-end" cols="4">
                      <span
                        class="text-h8 font-weight-light"
                        v-text="tempGoles.reviewWordGole"
                      ></span>
                      <span class="subheading font-weight-light ml-1"
                        >回顾</span
                      >
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-6"
                    justify="space-between"
                    no-gutters
                    style="align-items: center"
                  >
                    <span style="width: 5rem">新词数量</span>
                    <v-slider
                      v-model="tempGoles.newWordGole"
                      track-color="grey"
                      always-dirty
                      dense
                      hide-details
                      :min="0"
                      :max="100"
                      :step="1"
                      color="#ffaf64"
                    >
                      <template v-slot:prepend>
                        <v-icon @click="countNewWord"> mdi-minus </v-icon>
                      </template>

                      <template v-slot:append>
                        <v-icon @click="plusNewWord"> mdi-plus </v-icon>
                      </template>
                    </v-slider></v-row
                  >
                  <v-row
                    class="mt-4"
                    justify="space-between"
                    no-gutters
                    style="align-items: center"
                  >
                    <span style="width: 5rem">复习词数</span>
                    <v-slider
                      v-model="tempGoles.reviewWordGole"
                      track-color="grey"
                      always-dirty
                      dense
                      hide-details
                      :min="5"
                      :max="200"
                      :step="5"
                      color="#ffaf64"
                    >
                      <template v-slot:prepend>
                        <v-icon @click="countReviewWord"> mdi-minus </v-icon>
                      </template>

                      <template v-slot:append>
                        <v-icon @click="plusReviewWord"> mdi-plus </v-icon>
                      </template>
                    </v-slider></v-row
                  >
                  <p
                    style="
                      font-size: 0.8rem;
                      font-weight: 400;
                      color: #bdbdbd;
                      margin-top: 1rem;
                    "
                  >
                    学习计划用于督促学习,达成计划后仍可超额学习
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false">
              取消
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveConfig"
              :loading="btnLoading"
              :disabled="btnLoading"
            >
              保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { buttonVibrate } from "@/mixin/mixin";
import { mapGetters } from "vuex";
import { deepClone, isObjEqual } from "@/utils/common";
import $api from "@/api/api";

export default {
  name: "goleDialog",
  mixins: [buttonVibrate],
  computed: {
    ...mapGetters({
      userPreferrence: "userPreferrence",
    }),
  },
  data: () => ({
    btnLoading: false,
    dialog: false,
    tempGoles: {
      timeGole: 60,
      newWordGole: 5,
      reviewWordGole: 50,
      classGole: 1,
    },
  }),
  methods: {
    saveConfig() {
      this.btnLoading = true;
      if (isObjEqual(this.tempGoles, this.userPreferrence.gole)) {
        this.btnLoading = false;
        this.dialog = false;
        return;
      }
      let postData = deepClone(this.tempGoles);
      $api
        .setUserGoleConfig(postData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.$toast.success(res.msg);
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.btnLoading = false;
          this.$store.commit("setUserPreferrenceHomeGole", postData);
          this.$emit("refreshCircle");
          this.dialog = false;
        });
    },
    showDialog() {
      this.dialog = true;
      this.tempGoles = deepClone(this.userPreferrence.gole);
    },
    plusTime() {
      this.zzzShort();
      this.tempGoles.timeGole += 10;
    },
    countTime() {
      this.zzzShort();
      this.tempGoles.timeGole -= 10;
    },
    plusNewWord() {
      this.zzzShort();
      this.tempGoles.newWordGole += 1;
    },
    countNewWord() {
      this.zzzShort();
      this.tempGoles.newWordGole -= 1;
    },
    plusReviewWord() {
      this.zzzShort();
      this.tempGoles.reviewWordGole += 5;
    },
    countReviewWord() {
      this.zzzShort();
      this.tempGoles.reviewWordGole -= 5;
    },
    plusClass() {
      this.zzzShort();
      this.tempGoles.classGole += 1;
    },
    countClass() {
      this.zzzShort();
      this.tempGoles.classGole -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-end {
  display: flex;
  align-items: flex-end;
}
.card-title {
  font-weight: 700;
  font-size: 1.1rem;
  letter-spacing: 0.15rem;
  color: #404040;
  margin-top: -0.5rem;
}
.right-button {
  position: absolute;
  right: 0.6rem;
  bottom: 0.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  color: #dc7841;
  font-size: 0.9rem;
  font-weight: 700;
  width: 6.5rem;
  ::v-deep .v-btn__content {
    opacity: 1 !important;
  }
}
</style>
